import React, { useEffect } from "react";
import Webcam from "react-webcam";
import useAuth from "../../../../hooks/useAuth";
import messages from "../../../../libs/messages";
import "./FaceDetect.css";

interface Props {
  apiKey: string;
}

const FaceDetect = ({ apiKey }: Props) => {
  const videoConstraints = {
    width: 480,
    height: 480,
    facingMode: "user",
  };

  const { webcamRef, detectHumanFace, webcamSrc, setAuthStatus } = useAuth();

  useEffect(() => {
    setAuthStatus(messages.HUMAN_DETECTING);
    setTimeout(async () => {
      await detectHumanFace(apiKey);
    }, 2000);
  }, []);

  return (
    <div className="camera-wrapper">
      {webcamSrc ? (
        <img src={webcamSrc} alt="user" className="auth-user-img" />
      ) : (
        <Webcam
          id="webcam"
          audio={false}
          style={{
            zoom: 0.5,
            height: "100%",
            width: "100%",
            borderRadius: "50%",
          }}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
      )}
    </div>
  );
};

export default FaceDetect;
