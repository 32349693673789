import React, { useRef, useState } from "react";
import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import useAuth from "../../../../hooks/useAuth";
import messages from "../../../../libs/messages";
import { AuthApiService } from "../../../../services/api/auth";
import "./RegistrationForm.css";

interface Props {
  handleCloseModal: () => void;
  getResponse: (res) => void;
  apiKey: string;
}

const RegistrationForm = ({ handleCloseModal, getResponse, apiKey }: Props) => {
  const [phoneValidate, setPhoneValidate] = useState("");
  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const phone = useRef(null);
  const pin = useRef(null);

  const { snap, setAuthStatus, authStatus } = useAuth();

  const handleUserRegister = async (e) => {
    e.preventDefault();
    const userData = {
      name: name.current?.value,
      email: email.current?.value,
      password: password.current?.value,
      phone: phone.current?.value,
      pin: pin.current?.value,
      profile_pic: snap,
    };

    const userPhone = doPhoneValidation();

    try {
      if (userPhone) {
        if (userData.profile_pic) {
          const response = await AuthApiService.userRegister(userData, apiKey);
          if (response.statusCode === 200) {
            getResponse({
              name: response?.payload?.name,
              email: response?.payload?.email,
              phoneNumber: response?.payload?.phoneNumber,
            });
            handleCloseModal();
          }
        } else {
          setAuthStatus(messages.USER_REGISTERE_FAILED);
        }
      } else {
        setAuthStatus(messages.USER_REGISTERE_FAILED);
      }
    } catch (error) {}
  };

  const doPhoneValidation = () => {
    setPhoneValidate("");
    if (phone.current?.value.match(/^(?:01)?(?:\d{11})$/)) {
      return true;
    } else if (
      phone.current?.value.match(/^(?:(?:\+|00)88|01)?(?:\d{11}|\d{13})$/)
    ) {
      setPhoneValidate("Please enter the number without +88 or 0088.");
      return false;
    } else {
      setPhoneValidate("Incorrect mobile number");
      return false;
    }
  };

  return (
    <div className="regitration-form ">
      <form className="" onSubmit={handleUserRegister}>
        <div className="input-container ">
          <input id="name" type="text" ref={name} required />
          <label className="label " htmlFor="name">
            Name
          </label>
        </div>
        <div className="input-container ">
          <input id="email" type="email" ref={email} required />
          <label className="label " htmlFor="email">
            Email
          </label>
        </div>
        <div className="input-container ">
          <input id="password" type="password" ref={password} required />
          <label className="label " htmlFor="password">
            Password (minimum length 8)
          </label>
        </div>
        <div className="input-container ">
          <input id="phone" type="number" ref={phone} required />
          <label className="label " htmlFor="phone">
            Phone (e.g. 01xx...)
          </label>
        </div>
        <div className="input-container ">
          <input id="pin" type="password" ref={pin} required maxLength={4} />
          <label className="label " htmlFor="pin">
            Create your 4 digit PIN
          </label>
        </div>
        {authStatus === messages.AUTHENTICATING ? (
          <button disabled>Sign Up</button>
        ) : (
          <button type="submit" className="">
            Sign Up
          </button>
        )}
      </form>
    </div>
  );
};

export default RegistrationForm;
