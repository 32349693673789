import { AuthApiService } from './../services/api/auth';
import { useRef } from 'react';
import { useState } from 'react';
import { dataURLtoFile } from '../utils';
import '@mediapipe/face_detection';
import '@tensorflow/tfjs-core';
// Register WebGL backend.
import '@tensorflow/tfjs-backend-webgl';
import * as faceDetection from '@tensorflow-models/face-detection';
import messages from '../libs/messages';

export const useFaceDetection = () => {


    const [authStatus, setAuthStatus] = useState("");
    const [snap, setSnap] = useState<File>();
    const [isUserExist, setIsUserExist] = useState(true)
    const [userId, setUserId] = useState<Number>()
    const [webcamSrc, setWebcamSrc] = useState("")
    const webcamRef = useRef(null);



    const loadModel = async () => {
        const model = faceDetection.SupportedModels.MediaPipeFaceDetector;
        let detector = await faceDetection.createDetector(model, { runtime: "tfjs", maxFaces: 10 });
        return detector;
    };

    const detectHumanFace = async (apiKey) => {
        setWebcamSrc("")
        setAuthStatus(messages.HUMAN_DETECTING);
        try {
            const detector = await loadModel();
            const canvas = webcamRef.current?.getCanvas();
            if (canvas) {
                const result = await detector.estimateFaces(canvas, { flipHorizontal: false })

                if (result.length === 1) {
                    setAuthStatus(messages.HUMAN_DETECTED);
                    let snapShot = await webcamRef.current?.getScreenshot()
                    let snapFile = dataURLtoFile(snapShot, "TextTrack.png");
                    setSnap(snapFile);
                    setWebcamSrc(snapShot)
                    setAuthStatus(messages.USERFACE_VARIFYING);
                    if (snapShot && apiKey) {
                        const response = await AuthApiService.faceAuth(snapFile, apiKey)
                        if (response.statusCode === 200) {
                            setAuthStatus(messages.FACE_DETECTED)
                            setUserId(response?.user_id)
                            setIsUserExist(true)
                        } else {
                            setIsUserExist(false)
                            setAuthStatus(messages.FACE_VERIFY_FAILED)
                        }
                    }

                } else if (result.length > 1) {
                    window.requestAnimationFrame(detectHumanFace);
                } else {
                    window.requestAnimationFrame(detectHumanFace);
                }
            }

        } catch (error) { }
    };


    return {
        detectHumanFace,
        webcamRef,
        snap,
        authStatus,
        setAuthStatus,
        isUserExist,
        setSnap,
        userId,
        setWebcamSrc
    }
}