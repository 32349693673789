import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./NumericKeyPad.css";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
  onKeyPress: any;
  onChange: () => void;
}

const NumericKeyPad = ({ onKeyPress, onChange }: Props) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        className="key-pad-container"
      >
        <Keyboard
          onKeyPress={onKeyPress}
          onChange={onChange}
          layout={{
            default: ["0 1 2 3", "4 5 6 7", "8 9 {bksp}"],
          }}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default NumericKeyPad;
