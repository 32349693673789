import { useEffect, useState } from "react";
import "../../assets/css/userAuth.css";
import GazeFaceMaze from "./components/GazeFaceMaze/GazeFaceMaze";

const UserAuthPage = () => {
  const [show, setShow] = useState<boolean>(false);
  const [authResponse, setAuthResponse] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  return (
    <div className="user-auth">
      {authResponse.email === "" && (
        <button
          onClick={() => {
            setShow(true);
          }}
        >
          Authenticate by face
        </button>
      )}
      {authResponse.email && (
        <div className="user-info">
          <h1>Hello {authResponse?.name}!</h1>
          <p>Handover to Aurtho</p>
          <button
            onClick={() => {
              setAuthResponse({
                name: "",
                email: "",
                phoneNumber: "",
              });
            }}
          >
            Logout
          </button>
        </div>
      )}
      {show && (
        <GazeFaceMaze
          apiKey="Jid9rrj-8E4TDp2J"
          handleCloseModal={() => {
            setShow(false);
          }}
          getResponse={(res) => {
            setAuthResponse(res);
          }}
        />
      )}
    </div>
  );
};

export default UserAuthPage;
