const messages = {
    HUMAN_DETECTING: "Please hold still, taking a snap...",
    HUMAN_DETECTED: "Snap taken",
    FACE_DETECTED: "Face detected",
    MULTIPLE_FACES_DETECTED: "Multiple faces detected",
    FACE_VERIFY_FAILED: "New User",
    MODEL_LOADED: "Model loaded",
    MODEL_LOADING: "Model loading",
    MODEL_LOADING_ERROR: "Model loading error",
    MODEL_LOADING_ERROR_DETAILS: "Model loading error details",
    USERFACE_VARIFYING: "Verifying...",
    USERFACE_VARIFIED: "Verified... Moving to next section",
    USERFACE_VARIFY_FAILED: "Not verified...",
    AUTHENTICATING: "Authenting user...",
    AUTH_SUCCESS: "Authenting successfull",
    AUTH_FAILED: "Authenting failed",
    USER_DATA_FETCHING: "User data fetching",
    USER_DATA_FETCHED: "User data fetched",
    USER_REGISTERED: "User register",
    USER_REGISTERE_FAILED: "User register failed"
};
export default messages