import React, { useEffect, useState } from "react";
import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import useAuth from "../../../../hooks/useAuth";
import messages from "../../../../libs/messages";
import { AuthApiService } from "../../../../services/api/auth";
import { dataURLtoFile } from "../../../../utils";
import { setCookie } from "../../../../utils/cookieUtils";
import FaceDetect from "../FaceDetect/FaceDetect";
import LoginForm from "../LoginForm/LoginForm";
import NumericKeyPad from "../NumericKeyPad/NumericKeyPad";
import PinInput from "../PinInput/PinInput";
import RegistrationForm from "../RegistrationForm/RegistrationForm";
import "./GazeFaceMaze.css";

interface Props {
  apiKey: string;
  handleCloseModal: () => void;
  getResponse: (res) => void;
}

const GazeFaceMaze = ({ apiKey, handleCloseModal, getResponse }: Props) => {
  const [pin, setPin] = useState("");

  const { setAuthStatus, snap, isUserExist, authStatus } = useAuth();

  useEffect(() => {
    if (pin.length === 4) {
      let snapFile = dataURLtoFile(snap, "TextTrack.png");
      setAuthStatus(messages.AUTHENTICATING);
      AuthApiService.userLoginWithPin({ image: snapFile, pin }, apiKey).then(
        async (response) => {
          if (response.statusCode === 200) {
            getResponse({
              name: response?.payload?.name,
              email: response?.payload?.email,
              phoneNumber: response?.payload?.phoneNumber,
            });
            setAuthStatus(messages.AUTH_SUCCESS);
            setAuthStatus(messages.USER_DATA_FETCHING);
            setAuthStatus(messages.USER_DATA_FETCHED);
            setCookie("currentUser", response, 7);
            handleCloseModal();
          }
        }
      );
    }
  }, [pin]);

  // This function for getting pin from keypad
  const handlePin = (key) => {
    if (key === "{bksp}") {
      setPin(pin.slice(0, -1));
    } else {
      setPin((pin + key).substr(0, 4));
    }
  };

  return (
    <div className="user-auth-modal">
      <div className="user-auth-container">
        {authStatus === messages.AUTHENTICATING && (
          <div className="auth-loader">
            <ButtonLoader />
          </div>
        )}
        <svg viewBox="0 0 12 12" onClick={handleCloseModal}>
          <line x1="1" y1="11" x2="11" y2="1" stroke="black" strokeWidth="2" />
          <line x1="1" y1="1" x2="11" y2="11" stroke="black" strokeWidth="2" />
        </svg>
        <div className="web-cam-container">
          <div className="web-cam-wrapper">
            {apiKey && <FaceDetect apiKey={apiKey} />}
          </div>
          <p>{authStatus}</p>
          {authStatus === messages.FACE_DETECTED && (
            <div className="auth-pin-input">
              <PinInput inputValue={pin[0] && pin[0]} />
              <PinInput inputValue={pin[1]} />
              <PinInput inputValue={pin[2]} />
              <PinInput inputValue={pin[3]} />
            </div>
          )}
          {authStatus === messages.FACE_DETECTED && (
            <NumericKeyPad onChange={() => {}} onKeyPress={handlePin} />
          )}
        </div>
        <div className="auth-form-container">
          <div className="form-wrapper ">
            <div className="form-info">
              <h1 className="">{isUserExist ? "Sign In" : "Sing up"}</h1>
            </div>
            <div className="form-section ">
              {authStatus === messages.FACE_VERIFY_FAILED ? (
                <RegistrationForm
                  handleCloseModal={handleCloseModal}
                  getResponse={getResponse}
                  apiKey={apiKey}
                />
              ) : (
                <LoginForm
                  handleCloseModal={handleCloseModal}
                  getResponse={getResponse}
                  apiKey={apiKey}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GazeFaceMaze;
