import { useFaceDetection } from '../hooks/useFaceDetection';
import { createContext } from "react";

export const AuthContext = createContext(null);


const AuthProvider = ({ children }) => {
    const faceDecection = useFaceDetection()

    return (
        <AuthContext.Provider value={faceDecection}>{children}</AuthContext.Provider>
    );
}

export default AuthProvider