import "./PinInput.css";

interface Props {
  inputValue: string;
}

const PinInput = ({ inputValue }: Props) => {
  return (
    <div>
      <input
        type="password"
        placeholder="-"
        className="pin-input"
        value={inputValue?.length === 1 ? inputValue : ""}
        disabled
        maxLength={1}
      />
    </div>
  );
};

export default PinInput;
