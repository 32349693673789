import React, { useState } from "react";
import ButtonLoader from "../../../../components/ButtonLoader/ButtonLoader";
import useAuth from "../../../../hooks/useAuth";
import messages from "../../../../libs/messages";
import { AuthApiService } from "../../../../services/api/auth";
import { setCookie } from "../../../../utils/cookieUtils";
import "./LoginForm.css";

interface Props {
  handleCloseModal: () => void;
  getResponse: (res) => void;
  apiKey: string;
}

const LoginForm = ({ handleCloseModal, getResponse, apiKey }: Props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setAuthStatus, snap, authStatus } = useAuth();

  const loginWithEmail = async (e) => {
    e.preventDefault();

    setAuthStatus(messages.AUTHENTICATING);
    try {
      const response = await AuthApiService.userLoginWithEmail(
        {
          email,
          password,
          image: snap,
        },
        apiKey
      );
      if (response.statusCode === 200) {
        getResponse({
          name: response?.payload?.name,
          email: response?.payload?.email,
          phoneNumber: response?.payload?.phoneNumber,
        });
        setAuthStatus(messages.AUTH_SUCCESS);

        setAuthStatus(messages.USER_DATA_FETCHING);

        setAuthStatus(messages.USER_DATA_FETCHED);

        setCookie("currentUser", response, 7);
        handleCloseModal();
      } else {
        setAuthStatus(messages.AUTH_FAILED);
      }
    } catch (error) {}
  };

  return (
    <div className="login-form ">
      <form className="" onSubmit={loginWithEmail}>
        <div className="input-container ">
          <input
            id="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className="label " htmlFor="email">
            Email
          </label>
        </div>
        <div className="input-container ">
          <input
            id="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <label className="label " htmlFor="password">
            Password
          </label>
        </div>
        {authStatus === messages.AUTHENTICATING ? (
          <button disabled>Sign In</button>
        ) : (
          <button type="submit" className="">
            Sign In
          </button>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
