
const baseUrl = "https://face-api.gazepay.io/api/v1/";




export const AuthApiService = {
    faceAuth: async (snapFile, apiKey) => {
        const formData = new FormData();
        await formData.append('image', snapFile);
        const response = await fetch(`${baseUrl}face-maze/find-closest-match/`, {
            method: 'PUT',
            body: formData,
            headers: {
                "x-api-key": `${apiKey}`
            }
        });
        return response.json();
    },


    userLoginWithEmail: async (userData: { email: string, password: string, image: any }, apiKey) => {
        const formData = new FormData();
        formData.append("image", userData.image)
        formData.append("email", userData.email)
        formData.append("password", userData.password)
        const response = await fetch(`${baseUrl}auth/login`, {
            method: 'POST',
            body: formData,
            headers: {
                "x-api-key": `${apiKey}`
            }
        });

        return response.json();
    },

    userLoginWithPin: async (userData: { pin: string, image: any }, apiKey) => {
        const formData = new FormData();
        formData.append("image", userData.image)
        formData.append("pin", userData.pin)
        const response = await fetch(`${baseUrl}auth/login`, {
            method: 'POST',
            body: formData,
            headers: {
                "x-api-key": `${apiKey}`
            }
        });

        return response.json();
    },

    userRegister: async (userData, apiKey) => {
        const formData = new FormData();

        formData.append('name', userData.name);
        formData.append('email', userData.email);
        formData.append('phoneNumber', userData.phone);
        formData.append("password", userData.password);
        formData.append('address', userData.address);
        formData.append('pin', userData.pin);
        formData.append('image', userData.profile_pic);


        const response = await fetch(`${baseUrl}auth/register`, {
            method: "POST",
            body: formData,
            headers: {
                "x-api-key": `${apiKey}`
            }

        })
        return response.json()
    },


};
